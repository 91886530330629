<template>
  <Fieldset legend="จัดการข้อมูลผู้สูงอายุ">
    <div class="p-grid p-jc-between">
      <!-- left -->
      <div class="p-col-9">
        <div class="p-d-flex p-grid">
          <div class="p-mb-4 p-lg-4 p-fluid">
            <Button
              label="สำรวจครั้งที่ 1 สำรวจข้อมูลผู้สูงอายุเพื่อประเมินศักยภาพการทำงาน"
              class="p-button-sm p-mr-2 p-mb-2" 
              :class=" lotOfSurvey=='1'? 'p-button-primary' : 'p-button-outlined'"
              @click="fetchElderliesOrderMappingCode(1)"
            />
          </div>
          <div class="p-mb-4 p-lg-4 p-fluid">
            <Button
              label="สำรวจครั้งที่ 2 สำรวจข้อมูลผู้สูงอายุที่เข้าโปรแกรมยกระดับความสามารถในการทำงาน"
              class="p-button-sm p-mr-2 p-mb-2"
              :class=" lotOfSurvey=='2'? 'p-button-primary' : 'p-button-outlined'"
              @click="fetchElderliesOrderMappingCode(2)"
            />
          </div>
        </div>
      </div>
      <div class="p-col-9">
        <div class="p-d-flex p-grid">
          <div class="p-mb-3 p-lg-3 p-fluid">
            <Button
              label="นำเข้าข้อมูล"
              icon="pi pi-download"
              class="p-button-primary p-button-sm p-mr-2 p-mb-2"
              @click="$router.push('/elder/import/nonhealth')"
            />
          </div>
          <div class="p-mb-2 p-lg-3 p-fluid">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="dataFilters['global'].value"
                placeholder="ค้นหาข้อมูลผู้สูงอายุ"
              />
            </span>
          </div>
          <div class="p-mb-3 p-lg-3 p-fluid">
            <Button
              label="รีเฟรชข้อมูล"
              :icon="['pi pi-spinner', isRefreshing ? 'pi-spin' : '']"
              class="p-button-info p-button-sm p-mr-2 p-mb-2"
              @click="fetchElderliesOrderMappingCode(lotOfSurvey)"
            />
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="p-col-3" v-if="userLevel == 'AC'">
        <Dropdown
          id="areaId"
          v-model="criteriaFilter['areaId']"
          :options="areas"
          optionLabel="areaName"
          optionValue="codeId"
          placeholder="กรองข้อมูล พื้นที่"
          :filter="true"
          class="p-d-flex"
          @change="fetchElderliesOrderMappingCode(lotOfSurvey)"
        />
      </div>
    </div>

    <DataTable
      :value="elders"
      ref="dt"
      dataKey="id"
      :paginator="true"
      :rows="20"
      v-model:filters="dataFilters"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 50, 100]"
      currentPageReportTemplate="แสดง {first} ถึง {last} จากข้อมูล {totalRecords}"
      class="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
    >
      <Column
        field="no"
        header="ลำดับที่"
        :sortable="true"
        style="width: 10%; text-align: center"
      ></Column>
      <Column
        field="mappingCode"
        header="รหัสผู้สูงอายุ"
        :sortable="true"
      ></Column>
      <Column field="genderTh" header="เพศ" :sortable="true"></Column>
      <Column
        field="birthdayAc"
        header="วันเดือนปีเกิด"
        :sortable="true"
      ></Column>
      <Column :exportable="false" header="จัดการ">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            :label="slotProps.data.surveyName ? 'แก้ไขข้อมูล' : 'สำรวจข้อมูล'"
            class="p-button-sm p-mr-3"
            :class="[
              slotProps.data.surveyName
                ? 'p-button-primary'
                : 'p-button-success'
            ]"
            @click="editElder(slotProps.data)"
            style="white-space: nowrap"
          />
        </template>
      </Column>
    </DataTable>
  </Fieldset>
  <!-- Delete Dialog -->
  <ConfirmDelete @onConfirmDeleted="confirmDeleted" />
  <!--/ Delete Dialog -->
</template>
<script>
import * as moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import ConfirmDelete from '@/components/dialogs/ConfirmDelete'

export default {
  name: 'datatable',
  layout: 'Private',
  data() {
    return {
      elder: null,
      isRefreshing: false,
      lotOfSurvey: 1,
      dataFilters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
        //'area.codeId': { value: null, matchMode: FilterMatchMode.EQUALS }
      },
      criteriaFilter: {
        areaId: 'ALL'
      }
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      await this.fetchElderliesOrderMappingCode(1)
      // save activity log
      this.$store.dispatch('activity/saveActionActivityLog', {
        actMenu: 'MANAGE_SURVEY'
      })
    } catch (error) {
      //console.log('error ::==',error)
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  computed: {
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    areas() {
      const areas = this.$store.state.area.areas
      console.log('areas ::==', areas)
      return [
        {
          areaName: 'ทั้งหมด',
          codeId: 'ALL'
        },
        ...areas
      ]
    },
    elders() {
      const {
        codeId: userId,
        userLevel: userLevel,
        area: userArea
      } = this.$store.getters['oauth/session']
      const { dateFormatGet } = this.$primevue.config.locale
      return this.$store.state.elderly.elderlies.map((elder, idx) => {
        const { gender, birthday, mappingCode } = elder
        return {
          no: idx + 1,
          ...elder,
          genderTh: gender ? (gender == 'M' ? 'ชาย' : 'หญิง') : '-',
          birthdayAc: this.toDateAc(birthday)
        }
      })
    }
  },
  methods: {
    toDateAc(birthday) {
      if (!birthday || birthday == null) {
        return '-'
      } else {
        const { dateFormatGet } = this.$primevue.config.locale
        return moment(birthday, dateFormatGet)
          .add(543, 'years')
          .format(dateFormatGet)
      }
    },
    confirmDeleteElderDialog(elder) {
      this.elder = elder
      this.$store.commit('dialog/setConfirmDelete', true)
    },
    async confirmDeleted() {
      try {
        console.log('this.elder ::==', this.elder)
        const { codeId } = this.elder
        await this.$store.dispatch('elderly/deleteElder', codeId)
        this.$store.commit('dialog/setConfirmDelete', false)
      } catch (error) {
        //console.log('error ::==',error)
        this.$toast.add({
          severity: 'error',
          summary: 'เกิดข้อผิดพลาดจากระบบ',
          detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
          life: 3000
        })
      }
    },
    editElder(elder) {
      this.elder = elder
      const { codeId } = this.elder
      //this.$store.commit('dialog/setConfirmDelete',true)
      this.$router.push({
        //path: `/elder/form/${codeId}`,
        name: 'ElderFormId',
        params: { id: codeId }
      })
    },
    async fetchElderliesOrderMappingCode(lotSurvey) {
      const {
        codeId: userId,
        userLevel: userLevel,
        area: userArea
      } = this.$store.getters['oauth/session']

      const { areaId: areaFilter } = this.criteriaFilter

      const payload = {
        userLevel: userLevel,
        areaId: userArea?.codeId,
        lotSurvey: lotSurvey
      }

      if ('AC' == userLevel && areaFilter && areaFilter != null) {
        if ('ALL' == areaFilter) {
          delete payload['areaId']
        } else {
          payload['areaId'] = areaFilter
        }
      }
      this.lotOfSurvey = lotSurvey
      this.isRefreshing = true
      console.log('payload ::== ', payload)

      this.$store.commit('elderly/setElderlies', [])
      await this.$store.dispatch(
        'elderly/fetchElderliesOrderMappingCode',
        payload
      )
      this.isRefreshing = false
    }
  },
  components: {
    ConfirmDelete: ConfirmDelete
  }
}
</script>
<style lang="scss" scoped>
.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  :deep(.p-datatable) {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
</style>