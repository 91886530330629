<template>
  <!-- Delete Dialog -->
  <Dialog
    v-model:visible="$store.state.dialog.confirmDelete"
    :style="{ width: '400px' }"
    header="หน้าจอยืนยันการทำรายการ"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>{{ locale.message }}</span>
    </div>
    <template #footer>
      <Button
        :label="locale.acceptLabel"
        icon="pi pi-check"
        class="p-button-danger"
        @click="() => $emit('onConfirmDeleted')"
      />
      <Button
        :label="locale.rejectLabel"
        icon="pi pi-times"
        class="p-button-text"
        @click="$store.commit('dialog/setConfirmDelete', false)"
      />
    </template>
  </Dialog>
  <!--/ Delete Dialog -->
</template>
<script>
export default {
  name: 'confirmDelete',
  computed: {
    locale() {
      return this.$primevue.config.locale.confirmDelete
    }
  }
}
</script>